import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-container',
  template: `<div class="tw-container tw-mx-auto tw-w-full">
    <div class="tw-px-2 md:tw-px-4 tw-w-full">
      <ng-content></ng-content>
    </div>
  </div>`,
  styles: [],
})
export class DeaContainerComponent {}
