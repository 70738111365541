export class ApiResponse {
  success?: boolean;

  constructor(json?: any) {
    if (!json) return;
    this.success = json['success'];
  }
}

type ApiErrorObj = {
  code?: number;
  title?: string;
  message?: string;
  details?: string;
};

export class ApiError extends ApiResponse {
  error?: ApiErrorObj | undefined;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    while (json['error']) json = json['error'];
    this.error = {
      code: json['code'],
      title: json['title'],
      message: json['message'],
      details: json['details'],
    };
  }
}

export class ApiSuccess extends ApiResponse {
  pagination?: ApiPagination;
  data?: any;

  constructor(json?: any) {
    super(json);
    this.data = json['data'];
    this.pagination = new ApiPagination(json['pagination']);
  }
}

export class ApiPagination {
  page?: number;
  limit?: number;
  lastPage?: number;
  items?: number;

  constructor(json?: any) {
    if (!json) return;
    this.page = json['page'];
    this.limit = json['limit'];
    this.lastPage = json['last_page'];
    this.items = json['items'];
  }

  canGetPrevPage(): boolean {
    if (this.page != undefined) return this.page - 1 >= 0;
    return false;
  }

  canGetNextPage(): boolean {
    if (this.page != undefined && this.lastPage != undefined) {
      return this.page + 1 <= this.lastPage;
    }
    return false;
  }

  getPrevPage(): number {
    if (this.canGetPrevPage() && this.page != undefined) return this.page - 1;
    return 0;
  }

  getNextPage(): number {
    if (this.canGetNextPage() && this.page != undefined) return this.page + 1;
    return 0;
  }
}
