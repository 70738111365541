import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class ResultService {
  constructor(private http: HttpClient) {}

  getListing(p: {
    page?: number;
    limit?: number;
    sort?: string;
    order?: number;
  }) {
    let params = new HttpParams();
    if (p.page != undefined) params = params.append('page', p.page);
    if (p.limit != undefined) params = params.set('limit', p.limit);
    if (p.sort != undefined) params = params.set('sort', p.sort);
    if (p.order != undefined) params = params.set('order', p.order);
    return this.http.get<ApiResponse>(API.speedTestResult(), {
      withCredentials: true,
      params: params,
    });
  }

  getCSV() {
    return this.http.get(API.speedTestResultsCSV(), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
