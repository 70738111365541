import { AppFingerprint } from '../interfaces/app-fingerprint.interface';
import { Injectable, EventEmitter } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private _intervalMs: number = environment.checkFrequency;
  public udated: EventEmitter<AppFingerprint> = new EventEmitter();
  public fingerprint?: AppFingerprint;

  constructor() {
    setInterval(() => this.getFingerprint(), this._intervalMs);
    this.getFingerprint(false);
  }

  getFingerprint(emit = true): void {
    fetch('/assets/fingerprint')
      .then((res) => res.json())
      .then((json) => {
        emit && this.fingerprint?.uuid !== json.uuid
          ? this.udated.emit(json)
          : (this.fingerprint = json);
      })
      .catch((error) => console.error('Error:', error));
  }
}
