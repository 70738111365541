import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public session: EventEmitter<boolean> = new EventEmitter();
  private _validSessionValue?: boolean;

  get validSession(): boolean | undefined {
    return this._validSessionValue;
  }

  setSession(value: boolean): void {
    this._validSessionValue = value;
    this.emitEvent();
  }

  constructor(private http: HttpClient) {}

  private emitEvent() {
    this.session.emit(this._validSessionValue);
  }

  checkSession(): void {
    this.http
      .get(API.session(), {
        withCredentials: true,
        responseType: 'json',
        headers: {
          'X-Auth-Mode': 'cookie',
          'Content-Type': 'application/json',
        },
      })
      .subscribe({
        next: () => (this._validSessionValue = true),
        error: () => (this._validSessionValue = false),
      })
      .add(() => this.emitEvent());
  }
}
