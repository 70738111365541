import { environment } from 'src/environments/environment';

export class API {
  // #region AUTH
  static login(): string {
    return `${environment.authUrl}/api/login`;
  }
  static logout(): string {
    return `${environment.authUrl}/api/logout`;
  }
  static token(): string {
    return `${environment.authUrl}/api/token`;
  }
  static session(): string {
    return `${environment.authUrl}/api/session`;
  }
  // #endregion AUTH
  // #region STATIC
  static deAmicisRingIcon(): string {
    return `${environment.staticUrl}/ring/ring-icon-b.png`;
  }
  static defaultThumbnail(): string {
    return `${environment.staticUrl}/user/thumbnail.png`;
  }
  static deaLogo(): string {
    return `${environment.staticUrl}/logo/dea.webp`;
  }
  // #endregion STATIC
  static speedTestResult(): string {
    return `${environment.apiUrl}/v1/items/wifi/speed-tests`;
  }

  static speedTestResultsCSV(): string {
    return `${environment.apiUrl}/v1/items/wifi/speed-test/listing/csv`;
  }
}
