import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  template: `<nav
    class="tw-w-full ng-primary-background tw-flex tw-flex-col tw-justify-center"
    style="height: var(--dea-navbar-h); margin-bottom: var(--dea-navbar-mb);"
  >
    <app-dea-container>
      <div class="tw-w-full tw-flex tw-justify-between tw-py-2">
        <div class="tw-my-auto tw-flex tw-flex-row tw-items-end tw-gap-2">
          <img
            class="tw-h-10"
            alt="De Amicis Ring"
            crossorigin="anonymous"
            src="https://static.deamicis.cloud/ring/ring-icon-w.png"
          />
          <span class="tw-text-xs tw-text-white -tw-mb-1">
            v{{ fingerprint?.version }}
          </span>
        </div>
        <p-button
          type="button"
          [outlined]="true"
          [disabled]="loading"
          (onClick)="doLogout()"
          [styleClass]="'tw-flex tw-justify-center logout-button'"
          *ngIf="validSession === true"
        >
          <div class="tw-text-xs tw-px-2 tw-text-white">Logout</div>
        </p-button>
      </div>
    </app-dea-container>
  </nav>`,
  styles: [],
})
export class NavbarComponent {
  public loading: boolean = false;

  get fingerprint() {
    return this._mainService.fingerprint;
  }

  get validSession(): boolean | undefined {
    return this._sessionService.validSession;
  }

  constructor(
    private _mainService: MainService,
    private _authService: AuthService,
    private _sessionService: SessionService
  ) {}

  doLogout(): void {
    this.loading = true;
    this._authService
      .logout()
      .subscribe({
        next: () => this._sessionService.checkSession(),
        error: () => this._sessionService.checkSession(),
      })
      .add(() => (this.loading = false));
  }
}
