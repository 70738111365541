import { AppFingerprint } from 'src/app/interfaces/app-fingerprint.interface';
import { MainService } from 'src/app/services/main.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-new-version-dialog',
  template: `<p-dialog
    header="Header"
    [breakpoints]="{ '640px': '95vw' }"
    [style]="{ width: '480px' }"
    [(visible)]="visible"
    [resizable]="false"
    [draggable]="false"
    [modal]="true"
  >
    <ng-template pTemplate="header">
      <span class="tw-text-2xl tw-font-semibold">Novità</span>
    </ng-template>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <div>
        È disponibile una nuova versione! Se lo desideri, premendo sul pulsante
        "Aggiorna", è possibile applicare l'aggiornamento in questo momento per
        usufruire subito delle nuove funzionalità. Per completare l'operazione
        saranno necessri solo pochi secondi.
      </div>
      <div class="tw-font-extralight tw-text-xs tw-flex tw-flex-col">
        <div class="tw-mb-1">Dettagli tecnici:</div>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-p-4 tw-rounded tw-border">
          <div
            class="tw-flex tw-flex-col tw-items-end tw-font-extralight tw-text-xs"
          >
            <div>Version:</div>
            <div>HASH:</div>
            <div>UUID:</div>
          </div>
          <div
            class="tw-flex tw-flex-col tw-items-start tw-font-extralight tw-text-xs"
          >
            <div>{{ fingerprint?.version }}</div>
            <div>{{ fingerprint?.hash }}</div>
            <div>{{ fingerprint?.uuid }}</div>
          </div>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        type="button"
        severity="primary"
        (click)="visible = false"
        [disabled]="loading"
        [link]="true"
        [styleClass]="
          'tw-flex tw-justify-center ng-primary-color hover:tw-underline'
        "
      >
        <div class="tw-font-normal tw-px-2">Più tardi</div>
      </p-button>
      <p-button
        type="button"
        severity="primary"
        [loading]="loading"
        (onClick)="doUpgradde()"
        [styleClass]="'tw-flex tw-justify-center'"
      >
        <div class="tw-font-normal tw-px-2">Aggiorna</div>
      </p-button>
    </ng-template>
  </p-dialog>`,
  styles: [],
})
export class DeaNewVersionDialogComponent {
  public fingerprint?: AppFingerprint;
  public loading: boolean = false;
  public visible: boolean = false;

  constructor(private _mainService: MainService) {
    this._mainService.udated.subscribe({
      next: (fingerprint: AppFingerprint) => {
        console.info('Fingerprint:', fingerprint);
        this.fingerprint = fingerprint;
        this.visible = true;
      },
    });
  }

  doUpgradde(): void {
    this.loading = true;
    setTimeout(() => window.location.reload(), 1000);
  }
}
