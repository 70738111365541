import { AppFingerprint } from 'src/app/interfaces/app-fingerprint.interface';
import { MainService } from 'src/app/services/main.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `<footer
    class="tw-flex tw-flex-col tw-justify-center"
    style="background-color: var(--highlight-bg); margin-top: var(--dea-footer-mt); height: var(--dea-footer-h);"
  >
    <app-dea-container>
      <div
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-end tw-items-center"
      >
        <div class="tw-font-semibold">Istituti E. De Amicis</div>
        <div class="tw-opacity-50" style="font-size: 0.75rem;">
          De Amicis Ring - Network
          <span style="font-size: 0.5rem;">v{{ fingerprint?.version }}</span>
        </div>
        <div class="tw-opacity-50" style="font-size: 0.45rem;">
          Hash: {{ fingerprint?.hash }} | UUID: {{ fingerprint?.uuid }}
        </div>
        <div class="tw-mt-2 tw-opacity-35" style="font-size: 0.5rem;">
          <a
            class="hover:tw-underline"
            href="https://www.gabrielebellani.com"
            title="Gabriele Bellani"
            target="_blank"
          >
            Made with <span class="tw-opacity-45">♥︎</span> by G
          </a>
        </div>
      </div>
    </app-dea-container>
  </footer>`,
  styles: [],
})
export class FooterComponent {
  get fingerprint(): AppFingerprint | undefined {
    return this._mainService.fingerprint;
  }

  constructor(private _mainService: MainService) {}
}
