import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './web/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
// PRIME-NG
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
// COMPONENTS
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar.component';
import { FooterComponent } from './components/footer.component';
import { DeaSpinnerComponent } from './components/dea-pack/dea-spinner.component';
import { DeaNewVersionDialogComponent } from './components/dea-pack/dea-new-version-dialog.component';
import { DeaContainerComponent } from './components/dea-pack/dea-container.component';
import { EnableSessionComponent } from './components/enable-session.component';
import { ResultLisitngComponent } from './components/result-lisitng/result-lisitng.component';
import { PaginatorComponent } from './components/dea-pack/paginator.component';

@NgModule({
  declarations: [
    AppComponent,
    EnableSessionComponent,
    ResultLisitngComponent,
    DeaNewVersionDialogComponent,
    DeaSpinnerComponent,
    NavbarComponent,
    FooterComponent,
    DeaContainerComponent,
    PaginatorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ProgressSpinnerModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    FormsModule,
  ],
  providers: [
    DialogService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
