import { SpeedTestResult } from 'src/app/models/speed-test-result.model';
import { ResultService } from 'src/app/services/result.service';
import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { formatDate } from '../../utils/date';
import { MessageService } from 'primeng/api';
import {
  ApiError,
  ApiSuccess,
  ApiPagination,
} from 'src/app/models/api/api-response.model';

interface ApiBaseFilter {
  txt?: string;
  sort?: string;
  order?: number;
}

interface SpeedTestResultFilter extends ApiBaseFilter {
  date?: string;
}

@Component({
  selector: 'app-result-lisitng',
  templateUrl: './result-lisitng.component.html',
  styleUrls: ['./result-lisitng.component.scss'],
})
export class ResultLisitngComponent implements OnInit {
  private _messageService: MessageService = inject(MessageService);
  private _resultService: ResultService = inject(ResultService);
  public formatDate = (date: Date): string => formatDate(date);
  public speedTestResults: SpeedTestResult[] = [];
  public lastRequest: Date = new Date();
  public loading: boolean = true;

  public pagination?: ApiPagination;
  public filter?: SpeedTestResultFilter;

  public formGroup: FormGroup = new FormGroup({
    date: new FormControl<Date | null>(null),
  });

  ngOnInit(): void {
    this.getData();
  }

  customSort(event: any) {
    if (!this.filter) this.filter = {};
    if (this.filter.sort?.toString() == event.field) {
      Object.assign(this.filter, {
        order:
          this.filter.order != undefined
            ? this.filter.order == 1
              ? -1
              : 1
            : 1,
      });
    } else {
      Object.assign(this.filter, { sort: event.field, order: event.order });
    }
    this.getData();
  }

  onPage(page: number) {
    if (this.pagination) this.pagination.page = page;
    this.getData();
  }

  onLimit(limit: number) {
    if (this.pagination) this.pagination.limit = limit;
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.speedTestResults = [];
    let p = { page: this.pagination?.page, limit: this.pagination?.limit };
    if (this.filter?.sort) Object.assign(p, { sort: this.filter.sort });
    if (this.filter?.order) Object.assign(p, { order: this.filter.order });
    if (this.filter?.date) Object.assign(p, { date: this.filter.date });
    this._resultService
      .getListing(p)
      .subscribe({
        next: (data: ApiSuccess) => {
          this.speedTestResults = data.data?.map(
            (json: any) => new SpeedTestResult(json)
          );
          this.pagination = new ApiPagination(data.pagination);
        },
        error: (error: ApiError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Attenzione:',
            detail:
              error?.error?.message || 'Sembra si sia verificato un errore...',
          });
        },
      })
      .add(() => {
        this.loading = false;
        this.lastRequest = new Date();
      });
  }

  getCSV(): void {
    this.loading = false;
    this._resultService
      .getCSV()
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let a = document.createElement('a');
        a.download =
          res.headers.get('originalname') ||
          `${new Date().toISOString()} - Wi-Fi Measurements.csv`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      })
      .add(() => {
        this.loading = false;
      });
  }
}
