import { SessionService } from '../services/session.service';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpStatusCode,
  HttpInterceptor,
} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _sessionService: SessionService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error.status === HttpStatusCode.Unauthorized &&
          !error.url.includes('/login') &&
          !error.url.includes('credential')
        ) {
          if (!error.url.includes('token')) {
            return this.reAuthenticate().pipe(
              switchMap(() => next.handle(request))
            );
          }
        }
        return throwError(() => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            this._sessionService.validSession === true
          ) {
            this._sessionService.setSession(false);
          }
          return error;
        });
      })
    );
  }

  reAuthenticate(): Observable<any> {
    return this._authService.token();
  }
}
