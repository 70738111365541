type Headquarter = 'MILANO' | 'GORGONZOLA';

export class SpeedTestResult {
  id?: string;
  sede?: Headquarter;
  ssid?: string;
  position?: string;
  private_address?: string;
  public_address?: string;
  date?: Date;
  device?: string;
  server?: string;
  download?: number;
  upload?: number;
  note?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.sede = json['sede'];
    this.ssid = json['ssid'];
    this.position = json['position'];
    this.private_address = json['private_address'];
    this.public_address = json['public_address'];
    this.date = json['date'] ? new Date(json['date']) : undefined;
    this.device = json['device'];
    this.server = json['server'];
    this.download = json['download'];
    this.upload = json['upload'];
    this.note = json['note'];
  }
}
