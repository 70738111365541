<p-toast position="bottom-right"></p-toast>
<app-dea-new-version-dialog></app-dea-new-version-dialog>
<app-enable-session *ngIf="validSession === undefined || validSession === false"></app-enable-session>
<!-- MAIN PAGE -->
<app-navbar></app-navbar>
<main>
  <div *ngIf="validSession !== undefined && validSession === true">
    <app-dea-container>
      <app-result-lisitng></app-result-lisitng>
    </app-dea-container>
  </div>
</main>
<app-footer></app-footer>
<!-- MAIN PAGE -->