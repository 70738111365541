<div class="tw-my-8">
  <div class="tw-flex tw-flex-row tw-gap-4 tw-justify-start md:tw-items-center">
    <div class="tw-font-light tw-w-60">
      Data: {{lastRequest| date:'yyyy-MM-dd'}}<br>
      Ora: {{lastRequest| date:'HH:mm:ss'}}<br>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-end tw-w-full">
      <div class="tw-hidden md:tw-flex tw-gap-3">
        <p-button type="button" severity="primary" [outlined]="true" [loading]="loading" (onClick)="getData()"
          [styleClass]="'tw-flex tw-justify-center'">
          <div class="tw-hidden md:tw-block tw-font-normal tw-px-2">Aggiorna Dati</div>
        </p-button>
        <p-button type="button" severity="primary" [outlined]="true" [loading]="loading" (onClick)="getCSV()"
          [styleClass]="'tw-flex tw-justify-center'">
          <div class="tw-hidden md:tw-block tw-font-normal tw-px-2">Download CSV</div>
        </p-button>
      </div>
      <div class="tw-flex md:tw-hidden tw-gap-3">
        <p-button type="button" severity="primary" [outlined]="true" [disabled]="loading" (onClick)="getData()"
          [styleClass]="'tw-flex tw-justify-center'">
          <div class="tw-font-normal tw-px-2 tw-aspect-square tw-flex tw-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
              class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
              <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </div>
        </p-button>
        <p-button type="button" severity="primary" [outlined]="true" [disabled]="loading" (onClick)="getCSV()"
          [styleClass]="'tw-flex tw-justify-center'">
          <div class="tw-font-normal tw-px-2 tw-aspect-square tw-flex tw-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
              class="bi bi-filetype-csv" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z" />
            </svg>
          </div>
        </p-button>
      </div>
    </div>
  </div>
  <div class="tw-my-8">
    <!-- <div>
      <form [formGroup]="formGroup">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon tw-rounded-none">
            <i class="pi pi-calendar"></i>
          </span>
          <p-calendar formControlName="date" placeholder="Filtra per data" [classList]="'no-rounded-input'"
            [readonlyInput]="true">
          </p-calendar>
          <button type="button" pButton severity="primary" (click)="setNullDate()" title="Reset filtro data">
            <span class="tw-px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </span>
          </button>
        </div>
      </form>
    </div> -->
    <div class="tw-mt-4 tw-mb-1">Totale: {{pagination?.items}}</div>
    <div *ngIf="loading" class="tw-w-full tw-my-20 tw-flex tw-justify-center">
      <app-dea-spinner [size]="48"></app-dea-spinner>
    </div>
    <p-table *ngIf="!loading" [value]="speedTestResults" (sortFunction)="customSort($event)" [customSort]="true"
      [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pSortableColumn="date">Data <p-sortIcon field="date"></p-sortIcon></th>
          <th pSortableColumn="ssid">SSID <p-sortIcon field="ssid"></p-sortIcon></th>
          <th pSortableColumn="position">Posizione <p-sortIcon field="position"></p-sortIcon></th>
          <th pSortableColumn="download">Download <p-sortIcon field="download"></p-sortIcon></th>
          <th pSortableColumn="upload">Upload <p-sortIcon field="upload"></p-sortIcon></th>
          <th pSortableColumn="public_address">IP Pubblico <p-sortIcon field="public_address"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-speeTest let-columns="columns">
        <tr>
          <td>{{formatDate(speeTest.date)}}</td>
          <td>{{speeTest.ssid}}</td>
          <td>{{speeTest.position}}</td>
          <td>{{speeTest.download}}</td>
          <td>{{speeTest.upload}}</td>
          <td>{{speeTest.public_address}}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="tw-mt-8">
      <app-paginator [pagination]="pagination" (page)="onPage($event)" (limit)="onLimit($event)"></app-paginator>
    </div>
  </div>
</div>