import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-spinner',
  template: `<div class="overlay">
    <div
      aria-label="Loading"
      class="spinner center"
      [style.font-size.px]="size"
    >
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
      <div class="spinner-blade"></div>
    </div>
  </div>`,
  styles: [
    `
      $spinner-color: var(--primary-color) !default;
      $overlay-color: #fff !default;
      // $spinner-size: 48px !default;

      @mixin absolute-center {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      .overlay {
        background: rgba($overlay-color, 0);
        @include absolute-center;
      }

      .spinner {
        // font-size: $spinner-size;
        display: inline-block;
        position: relative;
        width: 1em;
        height: 1em;

        &.center {
          @include absolute-center;
        }
      }

      .spinner-blade {
        position: absolute;
        left: 0.4629em;
        bottom: 0;
        width: 0.074em;
        height: 0.2777em;
        border-radius: 0.5em;
        background-color: transparent;
        transform-origin: center -0.2222em;
        animation: spinner-fade 1s infinite linear;

        $animation-delay: 0s;
        $blade-rotation: 0deg;

        @for $i from 1 through 12 {
          &:nth-child(#{$i}) {
            animation-delay: $animation-delay;
            transform: rotate($blade-rotation);
            $blade-rotation: $blade-rotation + 30;
            $animation-delay: $animation-delay + 0.083;
          }
        }
      }

      @keyframes spinner-fade {
        0% {
          background-color: $spinner-color;
        }

        100% {
          background-color: transparent;
        }
      }
    `,
  ],
})
export class DeaSpinnerComponent {
  @Input() size: number = 16;
}
