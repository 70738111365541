import { AppFingerprint } from './interfaces/app-fingerprint.interface';
import { SessionService } from './services/session.service';
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  get validSession(): boolean | undefined {
    return this._sessionService.validSession;
  }

  constructor(
    private _primengConfig: PrimeNGConfig,
    private _sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this._primengConfig.ripple = true;
    this._sessionService.checkSession();
  }
}
