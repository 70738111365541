import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Component } from '@angular/core';

@Component({
  selector: 'app-enable-session',
  template: `<div
    style="background-color: rgba(0,0,0,0.35);"
    class="tw-z-50 tw-absolute tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center"
  >
    <div *ngIf="validSession === undefined">
      <app-dea-spinner [size]="64"></app-dea-spinner>
    </div>
    <div
      [formGroup]="loginForm"
      *ngIf="validSession != undefined"
      class="tw-bg-neutral-50 tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-rounded-md tw-border tw-w-[280px] tw-min-h-[500px]"
    >
      <div>
        <input
          pInputText
          class="tw-w-full"
          placeholder="Password"
          formControlName="password"
        />
      </div>
      <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-4 tw-max-w-[280px]">
        <p-button
          type="button"
          [outlined]="true"
          [disabled]="loading"
          (onClick)="addItem(item)"
          [styleClass]="'tw-w-full tw-aspect-square tw-flex tw-justify-center'"
          *ngFor="let item of items"
          severity="secondary"
        >
          <div class="tw-text-2xl">{{ item }}</div>
        </p-button>
      </div>
      <div class="tw-flex tw-flex-col tw-justify-stretch">
        <p-button
          type="button"
          severity="primary"
          [loading]="loading"
          (onClick)="doLogin()"
          [styleClass]="'tw-flex tw-justify-center tw-w-full tw-h-12'"
        >
          <div class="tw-font-normal tw-p-2">Attiva Sessione</div>
        </p-button>
      </div>
    </div>
  </div>`,
  styles: [],
})
export class EnableSessionComponent {
  public loading: boolean = false;
  public readonly items: string[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '*',
    '0',
    '#',
  ];

  public loginForm: FormGroup = new FormGroup({
    username: new FormControl({
      value: '0352d16a-66ad-476b-bf9a-e47fef2ca7ba',
      disabled: true,
    }),
    password: new FormControl({ value: '', disabled: true }),
  });

  get validSession(): boolean | undefined {
    return this._sessionService.validSession;
  }

  constructor(
    private _authService: AuthService,
    private _sessionService: SessionService,
    private _messageService: MessageService
  ) {}

  addItem(item: string): void {
    this.loginForm
      ?.get('password')
      ?.setValue((this.loginForm?.get('password')?.value || '') + item);
  }

  doLogin(): void {
    this.loading = true;
    setTimeout(() => {
      this._authService
        .login(this.loginForm.value)
        .subscribe({
          next: (_: any) => {
            this._sessionService.setSession(true);
            this._messageService.add({
              severity: 'success',
              summary: 'Benvenuto:',
              detail: 'Sessione abilitata con successo!',
            });
          },
          error: (_: any) => {
            this._sessionService.setSession(false);
            this._messageService.add({
              severity: 'error',
              summary: 'Errore:',
              detail: 'Ops... potresti aver sbagliato a digitare la password.',
            });
          },
        })
        .add(() => {
          this.loading = false;
          this.loginForm?.get('password')?.setValue('');
        });
    }, 1500);
  }
}
