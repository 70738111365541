import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiPagination } from 'src/app/models/api/api-response.model';

@Component({
  selector: 'app-paginator',
  template: `<div
    *ngIf="pagination"
    class="tw-flex tw-flex-row tw-justify-center tw-gap-4"
  >
    <p-dropdown
      (ngModelChange)="setLimit()"
      [(ngModel)]="selectedLimit"
      [options]="limits"
    ></p-dropdown>
    <div class="tw-hidden md:tw-flex tw-justify-center tw-items-center tw-px-2">
      <div class="tw-opacity-50 tw-text-sm">
        {{ current }} di {{ lastPage }}
      </div>
    </div>
    <p-button
      type="button"
      [outlined]="true"
      [disabled]="!enablePrev"
      (click)="setPage(-1)"
    >
      <div class="tw-px-2 tw-py-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="bi bi-chevron-left"
          fill="currentColor"
          viewBox="0 0 16 16"
          height="24"
          width="24"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
          />
        </svg>
      </div>
    </p-button>
    <p-button
      type="button"
      [outlined]="true"
      [disabled]="!enableNext"
      (click)="setPage(1)"
    >
      <div class="tw-px-2 tw-py-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="bi bi-chevron-right"
          fill="currentColor"
          viewBox="0 0 16 16"
          height="24"
          width="24"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </div>
    </p-button>
  </div>`,
  styles: [],
})
export class PaginatorComponent {
  @Input() pagination?: ApiPagination;
  @Output() page = new EventEmitter<number>();
  @Output() limit = new EventEmitter<number>();

  public readonly limits: number[] = [10, 20, 30, 40, 50];
  public selectedLimit: number = 20;

  get enablePrev(): boolean {
    return this.pagination ? this.pagination.canGetPrevPage() : false;
  }

  get enableNext(): boolean {
    return this.pagination ? this.pagination.canGetNextPage() : false;
  }

  get current(): string {
    return this.pagination?.page != undefined
      ? (this.pagination.page + 1).toString()
      : '-';
  }

  get lastPage(): string {
    return this.pagination?.lastPage != undefined
      ? (this.pagination.lastPage + 1).toString()
      : '-';
  }

  setPage(value: number) {
    if (this.pagination != undefined && this.pagination.page != undefined) {
      this.page.emit(this.pagination.page + value);
    }
  }

  setLimit() {
    this.limit.emit(this.selectedLimit);
  }
}
