import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data?: { username?: string; password?: string }) {
    return this.http.post(API.login(), data, {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  logout() {
    return this.http.get(API.logout(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  token() {
    return this.http.get(API.token(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }
}
